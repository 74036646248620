<template>
  <ul class="side-nav">
    <li class="side-nav-link" :class="{'active' : currentPath == 'home'}">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" fill="#BA667C" />
      </svg>
      <router-link
        :to="{ name: 'home'}"
      ><span>My Applications</span></router-link>
    </li>

    <li class="side-nav-link" :class="{'active' : currentPath == 'archive-applications'}">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
        <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0" fill="#BA667C"/>
      </svg>
      <router-link
        :to="{ name: 'archive-applications' }"
      ><span>Past Applications</span></router-link>
    </li>

    <li v-if="['parent', 'child'].includes(user.role)" class="side-nav-link" :class="{'active' : currentPath == 'family-list'}">
      <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12Z"
          fill="#BA667C"
        />
      </svg>
      <router-link
        :to="{ name: 'family-list' }"
      ><span>Family</span></router-link>
    </li>
    
    <li class="side-nav-link" :class="{'active' : currentPath == 'tasks'}">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
        <path d="M4 3V2H6C6 1.46957 6.21071 0.960859 6.58579 0.585786C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585786C9.78929 0.960859 10 1.46957 10 2H12V3C12 3.26522 11.8946 3.51957 11.7071 3.70711C11.5196 3.89464 11.2652 4 11 4H5C4.73478 4 4.48043 3.89464 4.29289 3.70711C4.10536 3.51957 4 3.26522 4 3ZM13.5 2.05042V3C13.4992 3.66281 13.2356 4.29825 12.7669 4.76693C12.2983 5.23561 11.6628 5.49924 11 5.5H5C4.33719 5.49924 3.70175 5.23561 3.23307 4.76693C2.76439 4.29825 2.50076 3.66281 2.5 3V2.05042C1.80188 2.16724 1.16773 2.52761 0.710067 3.06757C0.252406 3.60754 0.000836228 4.29218 0 5V15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H13C13.7956 18 14.5587 17.6839 15.1213 17.1213C15.6839 16.5587 16 15.7956 16 15V5C15.9992 4.29218 15.7476 3.60754 15.2899 3.06757C14.8323 2.52761 14.1981 2.16724 13.5 2.05042Z" fill="#BA667C"/>
      </svg>
      <router-link
        :to="{ name: 'tasks' }"
      ><span>Tasks</span></router-link>
      <div
        v-if="toDoTasksCount"
        class="overdue-tasks"
      >{{ toDoTasksCount }}</div>
    </li>

    <li v-if="['parent', 'child'].includes(user.role)" class="side-nav-link" :class="{'active' : currentPath == 'payment-portal'}">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="9" fill="#BA667C"/>
        <g clip-path="url(#clip0_1721_6241)">
          <path d="M8.99902 2.99902V14.999" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.7256 5.18066H7.63468C7.12835 5.18066 6.64277 5.3818 6.28475 5.73982C5.92672 6.09785 5.72559 6.58343 5.72559 7.08975C5.72559 7.59608 5.92672 8.08166 6.28475 8.43969C6.64277 8.79771 7.12835 8.99885 7.63468 8.99885H10.362C10.8683 8.99885 11.3539 9.19998 11.7119 9.55801C12.0699 9.91603 12.271 10.4016 12.271 10.9079C12.271 11.4143 12.0699 11.8998 11.7119 12.2579C11.3539 12.6159 10.8683 12.817 10.362 12.817H5.72559" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_1721_6241">
          <rect width="13.0909" height="13.0909" fill="white" transform="translate(2.45312 2.45312)"/>
          </clipPath>
        </defs>
      </svg>

      <router-link
        :to="{ name: 'payment-portal' }"
      ><span>Payment Portal</span></router-link>
    </li>
  </ul>
</template>

<script>
import { getUserData } from '@/auth/utils'

import storeModule from '@/views/Tasks/tasksStoreModule'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: "SideAccountNavigation",
  data: () => ({
    user: getUserData(),
    toDoTasksCount: 0,
  }),
  computed:{
    currentPath() {
      return this.$route.name
    },  
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-tasks'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  async created() {
    await store.dispatch('app-tasks/fetchTasksList', { status: 'To Do'}).then(response => {
      this.toDoTasksCount = response.data.data.length
    })
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.side-nav {
  width: 320px;
  height: fit-content;
  border: 1px solid #eceff9;
  border-radius: 8px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  &-link {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 900;
    font-size: 16px;
    line-height: 30px;
    & a, & svg > path, & svg > circle {
      color: $primary;
      fill: $primary;
    }
    &:hover a, &:hover svg > path, &:hover svg > circle {
      color: $primary;
      fill: $primary;
    }
    &.active a, &.active svg > path, &.active svg > circle{
      color: $primary;
      fill: $primary;
    }
    svg {
        width: 18px;
        height: 18px;
        margin-right: 9px;
    }
  }
}
.overdue-tasks {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  background: #B63D55;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .side-nav {
    margin: 15px 0;
    width: auto;
    height: auto;
    padding: 30px;
    &-link:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
